import React from "react"
import { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { CSSTransition } from "react-transition-group"
import CopyBtn from '../assets/img/copyBtn.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const ApiDocumentation = ({ location }) => {
  const [activeBlocks, setActiveBlocks] = useState([])

  const toggleBlockVisibility = blockId => {
    if (activeBlocks.includes(blockId)) {
      setActiveBlocks(activeBlocks.filter(id => id !== blockId))
    } else {
      setActiveBlocks([...activeBlocks, blockId])
    }
  }

  const urlCopied = () => toast("Url has been copied!");
  const requestCopied = () => toast("Request body has been copied!");

  const handleCopyText = (contentToCopy, isRequest) => {
    if (contentToCopy) {
      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          if (isRequest) {
            urlCopied()
          } else {
            requestCopied()
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const batchesGet200 = `
  {
    "batches": [
      {
        "id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312",
        "order_id": 144,
        "title": "Batch - Sat April 5 21:34:55 1975",
        "status": 1,
        "created_at": "2023-03-22T16:40:21Z",
        "updated_at": "2023-03-22T16:40:21Z"
      }
    ]
  }`
  const batchesGet400 = `
  {
    "message": "no valid data in request body",
    "invalid_letters": [
      {
        "0": {
          "address.city": [
            "The city field is required."
          ],
          "address.first_name": [
            "The first name field is required."
          ],
          "address.last_name": [
            "The last name field is required."
          ],
          "address.pdfname": [
            "The pdfname field is required."
          ],
          "address.state": [
            "The state field is required."
          ],
          "address.zip": [
            "The zip field is required."
          ],
          "mailing_options.envelope_type": [
            "The envelope type is required."
          ],
          "return_address.address_line1": [
            "The address line1 field is required."
          ],
          "return_address.city": [
            "The city field is required."
          ],
          "return_address.first_name": [
            "The first name field is required."
          ],
          "return_address.last_name": [
            "The last name field is required."
          ],
          "return_address.state": [
            "The state field is required."
          ],
          "return_address.zip": [
            "The zip field is required."
          ],
          "service_type": [
            "The service type field is required."
          ]
        }
      }
    ]
  }`
  const batchesGet422 = `
  {
    "batches": [
      {
        "id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312",
        "order_id": 144,
        "title": "Batch - Sat April 5 21:34:55 1975",
        "status": 1,
        "created_at": "2023-03-22T16:40:21Z",
        "updated_at": "2023-03-22T16:40:21Z"
      }
    ]
  }`
  const batchesPostRequest = `
  {
    "address_verification": true,
    "copy_diff_address_letter": true,
    "recipients": [
      {
        "unique_id": "144",
        "letter_id": "233",
        "address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "2476 Cambridge Place",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "Hanover",
          "state": "MD",
          "zip": "21076"
        },
        "return_address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "1526 First Street",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "New York",
          "state": "NY",
          "zip": "10022"
        },
        "mailing_options": {
          "envelope_type": "9x12",
          "service_type": "fc",
          "is_colored": true,
          "is_double_sided": false
        },
        "pdf_file_config": {
          "pdf_name": "letter_image_1.pdf",
          "pdf_page_numbers": "1-4"
        },
        "shadow": true
      }
    ]
  }`
  const batchesPostResponse200 = `
  {
    "invalid_letters": {},
    "batch_id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312"
  }`
  const batchesPostResponse400 = `
  {
    "message": "no valid data in request body",
    "invalid_letters": [
      {
        "0": {
          "address.city": [
            "The city field is required."
          ],
          "address.first_name": [
            "The first name field is required."
          ],
          "address.last_name": [
            "The last name field is required."
          ],
          "address.pdfname": [
            "The pdfname field is required."
          ],
          "address.state": [
            "The state field is required."
          ],
          "address.zip": [
            "The zip field is required."
          ],
          "mailing_options.envelope_type": [
            "The envelope type is required."
          ],
          "return_address.address_line1": [
            "The address line1 field is required."
          ],
          "return_address.city": [
            "The city field is required."
          ],
          "return_address.first_name": [
            "The first name field is required."
          ],
          "return_address.last_name": [
            "The last name field is required."
          ],
          "return_address.state": [
            "The state field is required."
          ],
          "return_address.zip": [
            "The zip field is required."
          ],
          "service_type": [
            "The service type field is required."
          ]
        }
      }
    ]
  }`
  const batchIdPostRequest = `
  {
    "address_verification": true,
    "copy_diff_address_letter": true,
    "recipients": [
      {
        "unique_id": "144",
        "letter_id": "233",
        "address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "2476 Cambridge Place",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "Hanover",
          "state": "MD",
          "zip": "21076"
        },
        "return_address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "1526 First Street",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "New York",
          "state": "NY",
          "zip": "10022"
        },
        "mailing_options": {
          "envelope_type": "9x12",
          "service_type": "fc",
          "is_colored": true,
          "is_double_sided": false
        },
        "pdf_file_config": {
          "pdf_name": "letter_image_1.pdf",
          "pdf_page_numbers": "1-4"
        },
        "shadow": "T"
      }
    ]
  }`
  const batchIdPostResponse200 = `
  {
    "invalid_letters": {},
    "batch_id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312"
  }`
  const batchIdPostResponse400 = `
  {
    "message": "no valid data in request body",
    "invalid_letters": [
      {
        "0": {
          "address.city": [
            "The city field is required."
          ],
          "address.first_name": [
            "The first name field is required."
          ],
          "address.last_name": [
            "The last name field is required."
          ],
          "address.pdfname": [
            "The pdfname field is required."
          ],
          "address.state": [
            "The state field is required."
          ],
          "address.zip": [
            "The zip field is required."
          ],
          "mailing_options.envelope_type": [
            "The envelope type is required."
          ],
          "return_address.address_line1": [
            "The address line1 field is required."
          ],
          "return_address.city": [
            "The city field is required."
          ],
          "return_address.first_name": [
            "The first name field is required."
          ],
          "return_address.last_name": [
            "The last name field is required."
          ],
          "return_address.state": [
            "The state field is required."
          ],
          "return_address.zip": [
            "The zip field is required."
          ],
          "service_type": [
            "The service type field is required."
          ]
        }
      }
    ]
  }`
  const batchIdLettersGetResponse200 = `
  {
    "is_successful": true,
    "letters": [
        {
            "id": 217947,
            "is_colored": true,
            "is_double_sided": false,
            "is_rre": true,
            "rre_enabled": false,
            "type": "cm-9x12-rre",
            "code": "9214890321486300010108",
            "serial_number": 1010,
            "status": 1,
            "letter_image": "https://letter-shop-nvi-dev.s3.us-west-1.amazonaws.com/09_04_2023_2pm/cm_rre_9x12_1side_clr/cm_rre_9x12_1side_clr_217947.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA24MWELNSCAWARITY%2F20230904%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230904T150928Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=16db7e13c47c525c767e914427e41766a8b27a4c4ee5872afdd306a7c27b8e53",
            "rre_image": "https://letter-shop-nvi-dev.s3.us-west-1.amazonaws.com/09_04_2023_2pm/cm_rre_9x12_1side_clr/cm_rre_9x12_1side_clr_217947.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA24MWELNSCAWARITY%2F20230904%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230904T150928Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=16db7e13c47c525c767e914427e41766a8b27a4c4ee5872afdd306a7c27b8e53",
            "sender": {
                "id": 254962,
                "first_name": "Leah",
                "last_name": "Miller",
                "company": "",
                "address_line_1": "1526 First Street",
                "address_line_2": "string",
                "city": "Coppel",
                "state": "TX",
                "zip": "10022",
                "type": 1,
                "identifier": "",
                "created_at": "2023-04-03T14:06:16Z",
                "updated_at": "2023-04-03T14:06:16Z"
            },
            "recipient": {
                "id": 254961,
                "first_name": "Leah",
                "last_name": "Miller",
                "company": "",
                "address_line_1": "2476 Cambridge Place",
                "address_line_2": "string",
                "city": "Philadelphia",
                "state": "PA",
                "zip": "19093",
                "type": 0,
                "identifier": "144",
                "created_at": "2023-04-03T14:06:16Z",
                "updated_at": "2023-04-03T14:06:16Z"
            },
            "tracking_info": {
              "TrackResponse": {
                  "TrackInfo": {
                      "@attributes": {
                          "ID": "420925189205590321486300010109"
                      },
                      "TrackDetail": [
                          {
                              "AuthorizedAgent": "false",
                              "DeliveryAttributeCode": "",
                              "Event": "Out for Delivery",
                              "EventCity": "PHILADEPLPHIA",
                              "EventCountry": "",
                              "EventDate": "April 7, 2023",
                              "EventState": "CA",
                              "EventTime": "6:10 am",
                              "EventZIPCode": "19093",
                              "FirmName": "",
                              "GMT": "13:10:00",
                              "GMTOffset": "-07:00",
                              "Name": ""
                          },
                          {
                              "AuthorizedAgent": "false",
                              "DeliveryAttributeCode": "",
                              "Event": "Arrived at Post Office",
                              "EventCity": "PHILADEPLPHIA",
                              "EventCountry": "",
                              "EventDate": "April 7, 2023",
                              "EventState": "PA",
                              "EventTime": "3:09 am",
                              "EventZIPCode": "12093",
                              "FirmName": "",
                              "GMT": "10:09:29",
                              "GMTOffset": "-07:00",
                              "Name": ""
                          },
                          {
                              "AuthorizedAgent": "false",
                              "DeliveryAttributeCode": "",
                              "Event": "Arrived at USPS Facility",
                              "EventCity": "PHILADEPLPHIA",
                              "EventCountry": "",
                              "EventDate": "April 6, 2023",
                              "EventState": "PA",
                              "EventTime": "2:50 pm",
                              "EventZIPCode": "12093",
                              "FirmName": "",
                              "GMT": "21:50:06",
                              "GMTOffset": "-07:00",
                              "Name": ""
                          },
                          {
                              "AuthorizedAgent": "false",
                              "DeliveryAttributeCode": "",
                              "Event": "Departed USPS Regional Facility",
                              "EventCity": "COPPELL TX DISTRIBUTION CENTER",
                              "EventCountry": "",
                              "EventDate": "April 5, 2023",
                              "EventState": "",
                              "EventTime": "5:55 am",
                              "EventZIPCode": "",
                              "FirmName": "",
                              "GMT": "",
                              "GMTOffset": "",
                              "Name": ""
                          },
                          {
                              "AuthorizedAgent": "false",
                              "DeliveryAttributeCode": "",
                              "Event": "Pre-Shipment Info Sent to USPS, USPS Awaiting Item",
                              "EventCity": "",
                              "EventCountry": "",
                              "EventDate": "April 3, 2023",
                              "EventState": "",
                              "EventTime": "",
                              "EventZIPCode": "",
                              "FirmName": "",
                              "GMT": "",
                              "GMTOffset": "",
                              "Name": ""
                          }
                      ],
                      "TrackSummary": {
                          "AuthorizedAgent": "false",
                          "DeliveryAttributeCode": "",
                          "Event": "Delivered, In/At Mailbox",
                          "EventCity": "PHILADELPHIA",
                          "EventCountry": "",
                          "EventDate": "April 7, 2023",
                          "EventState": "CA",
                          "EventTime": "10:51 am",
                          "EventZIPCode": "19093",
                          "FirmName": "",
                          "GMT": "17:51:56",
                          "GMTOffset": "-07:00",
                          "Name": ""
                      }
                  }
              }
          },
          "created_at": "2023-04-03T14:06:16Z",
          "updated_at": "2023-04-03T14:06:40Z"
        }
    ]
}`
  const batchIdLettersPatchResponse200 = `
  {
    "invalid_letters": {},
    "batch_id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312"
  }`
  const batchIdLettersPatchResponse422 = `
  {
    "missed_images": [
      "letter_1.pdf"
    ],
    "not_mapped_recipients": [
      {
        "unique_id": "144",
        "letter_id": "233",
        "address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "2476 Cambridge Place",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "Hanover",
          "state": "MD",
          "zip": "21076"
        },
        "return_address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "1526 First Street",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "New York",
          "state": "NY",
          "zip": "10022"
        },
        "mailing_options": {
          "envelope_type": "9x12",
          "service_type": "fc",
          "is_colored": true,
          "is_double_sided": false
        },
        "pdf_file_config": {
          "pdf_name": "letter_image_1.pdf",
          "pdf_page_numbers": "1-4"
        },
        "shadow": true
      }
    ]
  }`
  const batchIdRecipientsGetResponse200 = `
  {
    "is_successful": true,
    "mapped_recipients": [],
    "missed_images": [
        "letter_1.pdf"
    ],
    "not_mapped_recipients": [
        {
            "unique_id": "144",
            "letter_id": "233",
            "address": {
                "first_name": "Leah",
                "last_name": "Miller",
                "full_name": "",
                "company": "",
                "address_line_1": "2476 Cambridge Place",
                "address_line_2": "string",
                "city": "Hanover",
                "state": "MD",
                "zip": "21076"
            },
            "return_address": {
                "first_name": "Leah",
                "last_name": "Miller",
                "full_name": "",
                "company": "",
                "address_line_1": "1526 First Street",
                "address_line_2": "string",
                "city": "New York",
                "state": "NY",
                "zip": "10022"
            },
            "pdf_file_config": {
                "pdf_name": "letter_1.pdf",
                "pdf_page_numbers": "1",
                "pdf_process_type": ""
            },
            "mailing_options": {
                "envelope_type": "9x12",
                "service_type": "cm-rre",
                "is_colored": true,
                "is_double_sided": false
            },
            "shadow": true,
            "error_message": ""
        }
    ],
    "total_not_mapped_recipients": 1,
    "total_recipients": 1
}`
  const batchIdRecipientsPatchResponse = `
  {
    "address_verification": true,
    "copy_diff_address_letter": true,
    "recipients": [
      {
        "unique_id": "144",
        "letter_id": "233",
        "address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "2476 Cambridge Place",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "Hanover",
          "state": "MD",
          "zip": "21076"
        },
        "return_address": {
          "first_name": "Leah",
          "last_name": "Miller",
          "address_line_1": "1526 First Street",
          "address_line_2": "string",
          "company_name": "ABC Company",
          "city": "New York",
          "state": "NY",
          "zip": "10022"
        },
        "mailing_options": {
          "envelope_type": "9x12",
          "service_type": "fc",
          "is_colored": true,
          "is_double_sided": false
        },
        "pdf_file_config": {
          "pdf_name": "letter_image_1.pdf",
          "pdf_page_numbers": "1-4"
        },
        "shadow": true
      }
    ]
  }`
  const batchIdRecipientsPatchResponse200 = `
  {
    "invalid_letters": {},
    "batch_id": "36e7708b-5ef0-4baf-9ede-0cc9d8b9b312"
  }`
  const batchIdRecipientsPatchResponse400 = `
  {
    "message": "no valid data in request body",
    "invalid_letters": [
      {
        "0": {
          "address.city": [
            "The city field is required."
          ],
          "address.first_name": [
            "The first name field is required."
          ],
          "address.last_name": [
            "The last name field is required."
          ],
          "address.pdfname": [
            "The pdfname field is required."
          ],
          "address.state": [
            "The state field is required."
          ],
          "address.zip": [
            "The zip field is required."
          ],
          "mailing_options.envelope_type": [
            "The envelope type is required."
          ],
          "return_address.address_line1": [
            "The address line1 field is required."
          ],
          "return_address.city": [
            "The city field is required."
          ],
          "return_address.first_name": [
            "The first name field is required."
          ],
          "return_address.last_name": [
            "The last name field is required."
          ],
          "return_address.state": [
            "The state field is required."
          ],
          "return_address.zip": [
            "The zip field is required."
          ],
          "service_type": [
            "The service type field is required."
          ]
        }
      }
    ]
  }`
  const batchIdfulfillPutResponse200 = `
  {
    "success": true
  }`
  const batchIdfulfillPutResponse404 = `
  {
    "message": "batch was not found"
  }`
  const batchIdfulfillPutResponse422 = `
  {
    "message": "batch already fulfilled"
  }`

  return (
    <>
    {/* <Seo title="UploadLetters.com - Print and Mail API" description='Send First-Class, Certified, and Priority Mail instantly. Integrate with our online mailing service through the Print and Mail API. Mail letters online securely accurately and efficiently.' location={location.pathname} /> */}
    <MainLayout location={location}>
    <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
        </script>
      </Helmet>
      <div className="landingSlidesHolder">
        <div className="mainContainer gutter60">
          <div className="introApiDocsSlideContentWrapper">
            <div>
              <h1 className="introApiDocsSlideContentTitle">
                API Documentation
              </h1>
            </div>
            <div>
              <div className="introApiDocsSlideContentSubTitle">
                <div>
                  Using the <a href="https://uploadletters.com" target="_blank" className="link">uploadletters.com</a> enterprise API, you can send First-Class Mail®, Certified Mail® and Priority Mail® letters easily and instantly.
                </div>
                <div>
                  This document page is intended as a guide for developers who would like to integrate their web and mobile applications.
                </div>
              </div>
              <div>
                <p className="introApiDocsSlideContentList">
                  Here are a few items you need to consider before using the API:
                </p>
                <ul className="leftSideBlockHeadersContent introApiDocsSlideContentList">
                  <li>
                    The main object is a batch. A new batch can be created by POST /batches. After a batch is created, it can be modified by using “batchId”.
                  </li>
                  <li>Recipients per request limit: 100</li>
                  <li>Request size limit: 50Mb</li>
                  <li>Available USPS® service types: First-Class Mail® (“fc”), Certified Mail® (“cm”), Certified Mail® with Return Receipt Electronic® (“cm-rre”), and Priority Mail® (“pm”)</li>
                  <li>
                    Available envelope sizes: #10 envelope (“10”) and 9 x 12 (“9x12”). Envelope size for Priority Mail® is not a required field as it will always be mailed using a Flat Rate 12.5 x 9.5 envelope.
                  </li>
                  <li>
                    Letters and recipients are mapped by pdf_name under pdf_file_config. File Names must be unique.
                  </li>
                  <li>
                    Use POST /batches/{"{"}batchId{"}"}/fulfil to start
                    processing a configured batch
                  </li>
                  <li>
                    Generate an API key in your account settings at
                    https://app.uploadletters.com
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="bodyContainer">
            <div className="apiDocsSwaggerContainer">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block1")}
              >
                <p>POST https://api.uploadletters.com/batches</p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block1") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block1")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Creates a new batch with an initial set of recipients and their corresponding USPS® service types and envelope sizes.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  <h2 className="rightSideBlockExampleTitle">
                    REQUEST BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesPostRequest}</pre>
                      <button className="copyBtnRequest" onClick={() => {
                        handleCopyText(batchesPostRequest, false)
                      } }>
                        <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                      </button>
                    </div>
                  </div>
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesPostResponse200}</pre>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 400</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesPostResponse400}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block2")}
              >
                <p>
                  PATCH https://api.uploadletters.com/batches/{"{"}batchId{"}"}
                  /recipients
                </p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches/{batchId}/recipients",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block2") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block2")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Allows the user to add recipients and their corresponding USPS® service types and envelope sizes to an existing batch.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  <h2 className="rightSideBlockExampleTitle">
                    REQUEST BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesPostRequest}</pre>
                      <button className="copyBtnRequest" onClick={() => {
                        handleCopyText(batchIdRecipientsPatchResponse, false)
                      } }>
                        <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                      </button>
                    </div>
                  </div>
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdRecipientsPatchResponse200}</pre>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 400</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdRecipientsPatchResponse400}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block4")}
              >
                <p>
                  GET https://api.uploadletters.com/batches/{"{"}batchId{"}"}
                  /recipients
                </p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches/{batchId}/recipients",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block4") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block4")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Gets a list of all recipients by batchId prior to processing.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdRecipientsGetResponse200}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block5")}
              >
                <p>
                  PATCH https://api.uploadletters.com/batches/{"{"}batchId
                  {"}"}
                  /letters
                </p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches/{batchId}/letters",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block5") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block5")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Allows the user to add letter image(s) to a batch.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdLettersPatchResponse200}</pre>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 422</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdLettersPatchResponse422}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block7")}
              >
                <p>
                  PUT https://api.uploadletters.com/batches/{"{"}batchId{"}"}
                  /fulfill
                </p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches/{batchId}/fulfill",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block7") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block7")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Moves a batch to processing and deducts the cost of the batch from the wallet balance.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdfulfillPutResponse200}</pre>
                    </div>
                    <div>
                      <div className="rightSideBlockCodeTitle">CODE: 404</div>
                      <div className="rightSideBlockCodeContent">
                        <pre>{batchIdfulfillPutResponse404}</pre>
                      </div>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 422</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdfulfillPutResponse422}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block3")}
              >
                <p>GET https://api.uploadletters.com/batches</p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block3") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block3")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Gets all processed batches based on a specified date range.
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  {/* <p className="leftSideBlockHeadersTitle">PARAMETERS:</p>
    <p className="leftSideBlockHeadersContent">From: dateTime,</p>
    <p className="leftSideBlockHeadersContent">
      Type: number, // Available values [1, 2, 3, 4, 5, 6, 7, 10]
    </p>
    <p className="leftSideBlockHeadersContent">
      Status: number, // Available values [1, 2, 3]
    </p> */}
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesGet200}</pre>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 400</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesGet400}</pre>
                    </div>
                    <div className="rightSideBlockCodeTitle">CODE: 422</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchesGet422}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

            <div className="apiDocsSwaggerContainer ">
              <div
                className="leftSideBlockHttpLink"
                onClick={() => toggleBlockVisibility("block6")}
              >
                <p>
                  GET https://api.uploadletters.com/batches/{"{"}batchId
                  {"}"}
                  /letters
                </p>
                <button className="copyBtn" onClick={(e) => {
                  e.stopPropagation()
                  handleCopyText(
                    "https://api.uploadletters.com/batches/{batchId}/letters",
                    true
                  )
                } }>
                  <img src={CopyBtn} alt="Copy Icon" className="copyIcon" />
                </button>
                <div
                  className={`arrowIcon ${activeBlocks.includes("block6") ? "active" : ""}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.75L9.5 11.25L14 6.75"
                      stroke="#1880FF"
                      strokeWidth="1.50259"
                      strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <CSSTransition
                in={activeBlocks.includes("block6")}
                timeout={500}
                classNames="block-transition"
                unmountOnExit
              >
                <div>
                  <p className="leftSideBlockHeadersSubContent">
                    Gets a list of all letters in a batch after processing. This includes all the associated data with each processed letter in a batch including USPS® tracking data, letter images and Return Receipt Electronic® (RRE) images (if applicable).
                  </p>
                  <p className="leftSideBlockHeadersTitle">HEADERS:</p>
                  <p className="leftSideBlockHeadersContent">
                    Content-Type: application/json
                  </p>
                  <p className="leftSideBlockHeadersContent">
                    x-api-key: API_KEY
                  </p>
                  {/* <p className="leftSideBlockHeadersTitle">PARAMETERS:</p>
    <p className="leftSideBlockHeadersContent">
      Type: string, // Available values [fc-10, fc-9x12, cm-10,
      cm-9x12, cm-10, cm-9x12, pm]
    </p> */}
                  <h2 className="rightSideBlockExampleTitle">
                    RESPONSE BODY EXAMPLE:
                  </h2>
                  <div className="rightSideBlock">
                    <div className="rightSideBlockCodeTitle">CODE: 200</div>
                    <div className="rightSideBlockCodeContent">
                      <pre>{batchIdLettersGetResponse200}</pre>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>

          </div>
        </div>
      </div>
      <ToastContainer className="toast" />
    </MainLayout>
    </>
  )
}

ApiDocumentation.defaultProps = {
  location: {},
}

export default ApiDocumentation

export const Head = () => (
  <Seo title="UploadLetters.com - Print and Mail API" description="Send First-Class, Certified, and Priority Mail instantly. Integrate with our online mailing service through the Print and Mail API. Mail letters online securely accurately and efficiently." />
)
